// src/components/CookieConsent.tsx

import React, { useState } from 'react';

const CookieConsent: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(!localStorage.getItem('cookieConsentStatus'));

    const handleAccept = () => {
        window.cookieConsentStatus = { analytics: true };
        localStorage.setItem('cookieConsentStatus', JSON.stringify(window.cookieConsentStatus));
        setIsVisible(false);
    };

    const handleDecline = () => {
        window.cookieConsentStatus = { analytics: false };
        localStorage.setItem('cookieConsentStatus', JSON.stringify(window.cookieConsentStatus));
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div style={bannerStyle}>
            <p>Deneyiminizi iyileştirmek için çerezler kullanıyoruz. Kabul ederek, analiz amaçlı çerez kullanımımızı onaylamış oluyorsunuz.</p>
            <button style={buttonStyle} onClick={handleAccept}>Kabul Et</button>
            <button style={buttonStyle} onClick={handleDecline}>Reddet</button>
        </div>
    );
};

const bannerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(26, 26, 46, 0.9)', // Dark transparent background
    color: '#E1E1E6', // Light text color for readability
    textAlign: 'center',
    padding: '15px', // Increased padding for spacing
    fontSize: '0.9em',
    zIndex: 1000,
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)', // Subtle shadow for separation
    animation: 'fadeIn 0.5s ease',
};

// Button style for consistency with theme
const buttonStyle: React.CSSProperties = {
    backgroundColor: '#00A5E0', // Match button color
    color: '#FFF',
    border: 'none',
    padding: '8px 15px',
    margin: '0 5px',
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer',
    fontSize: '0.9em',
    transition: 'background-color 0.3s',
};

export default CookieConsent;
