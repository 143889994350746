function generateVisitorId() {
    const year = new Date().getFullYear();
    const month = String(new Date().getMonth() + 1).padStart(2, '0');
    const day = String(new Date().getDate()).padStart(2, '0');
    const datePart = `${year}${month}${day}`;
    const randomPart = Math.random().toString(36).substring(2, 12); // Generate a 6 char random string

    return `${datePart}-${randomPart}`;
}

// Cache the IDs in memory
let cachedVisitorId: string | null = null;
let cachedGAClientId: string | null = null;

export function getVisitorId() {
    if (cachedVisitorId) {
        return cachedVisitorId;
    }

    const storedVisitorId = localStorage.getItem('visitorId');
    
    if (storedVisitorId) {
        cachedVisitorId = storedVisitorId;
        return storedVisitorId;
    } else {
        const newVisitorId = generateVisitorId();
        localStorage.setItem('visitorId', newVisitorId);
        cachedVisitorId = newVisitorId;
        return newVisitorId;
    }
}

export function getGAClientId(): string {
    if (cachedGAClientId) {
        return cachedGAClientId;
    }

    const storedGAClientId = localStorage.getItem('ga_client_id');
    
    if (storedGAClientId) {
        cachedGAClientId = storedGAClientId;
        return storedGAClientId;
    }
    
    // Generate a GA4 compatible client ID (UUID v4)
    const newGAClientId = crypto.randomUUID();
    localStorage.setItem('ga_client_id', newGAClientId);
    cachedGAClientId = newGAClientId;
    return newGAClientId;
}