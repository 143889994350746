import { StoryBrief, StoryStartRestResponse, StoryStep } from '../stores/interfaces';
import ReactGA from 'react-ga4';
import { getGAClientId, getVisitorId } from '../utils/visitor';

const BASE_PROTOCOL = process.env.NODE_ENV === 'development' ? 'http://' : '';
const BASE_HOST = process.env.NODE_ENV === 'development' ? '127.0.0.1:8080' : '';
const API_BASE_URL = `${BASE_PROTOCOL}${BASE_HOST}`;

const defaultHeaders = {
    'Accept-Charset': 'utf-8',
    'Accept-Language': 'tr-TR,tr;q=0.9',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Visitor-Id': getVisitorId(),
    'X-GA-ID': getGAClientId()
} as const;

// Helper function to handle API calls
const apiCall = async <T>(
    url: string,
    eventCategory: string,
    eventAction: string,
    eventLabel?: string
): Promise<T> => {
    let response;
    try {
        ReactGA.event({
            category: eventCategory,
            action: eventAction,
            label: eventLabel
        });

        response = await fetch(`${API_BASE_URL}${url}`, {
            headers: defaultHeaders
        });
        
        if (!response.ok) {
            throw new Error(`API call failed with status ${response.status}`);
        }
        
        return response.json();
    } catch (error) {
        ReactGA.event({
            category: 'Error',
            action: `${eventAction} Failed`,
            label: eventLabel,
            value: response?.status || 0
        });
        throw error;
    }
};

export const ApiService = {
    getStories: () => 
        apiCall<StoryBrief[]>('/api/stories', 'Story', 'List Stories'),

    getStartStory: (storyId: string) =>
        apiCall<StoryStartRestResponse>(
            `/api/story/${storyId}/step`,
            'Story',
            'Start Story',
            storyId
        ),

    getNextStoryStep: (nextStepId: string) =>
        apiCall<StoryStep>(
            `/api/story-step/${nextStepId}`,
            'Story',
            'Next Step',
            nextStepId
        ),

};
