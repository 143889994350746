import { makeAutoObservable, runInAction, action } from 'mobx';
import { Story, StoryStep, Option } from './interfaces';
import { ApiService } from '../services/ApiService';

class StoryPageStore {
    currentStory: Story | null = null;
    steps: StoryStep[] = [];
    loading: boolean = false;
    error: string | null = null;
    isLoadingStory: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    resetState() {
        this.currentStory = null;
        this.steps = [];
        this.error = null;
    }

    async loadStory(storyId: string) {
        if (this.isLoadingStory === storyId) {
            return;
        }

        this.resetState();
        this.loading = true;
        this.isLoadingStory = storyId;

        try {
            const storyStart = await ApiService.getStartStory(storyId);
            runInAction(() => {
                this.currentStory = {
                    story_brief: storyStart.story_brief,
                    current_step: storyStart.root_step
                };
                this.steps = [storyStart.root_step];
                this.loading = false;
                this.isLoadingStory = null;
            });
        } catch (error) {
            runInAction(() => {
                this.error = 'Failed to load story';
                this.loading = false;
                this.isLoadingStory = null;
            });
        }
    }

    @action
    async selectOption(option: Option) {
        if (this.currentStory && this.currentStory.current_step) {
            this.currentStory.current_step.selected_option = option;
            // Make sure this change is reflected in the steps array as well
            const currentStepIndex = this.steps.findIndex(step => step.story_step_id === this.currentStory!.current_step.story_step_id);
            if (currentStepIndex !== -1) {
                this.steps[currentStepIndex].selected_option = option;
            }
        }
        this.loading = true;
        try {
            const next_step_id = this.currentStory?.current_step.story_step_id+'-'+option.option_id;
            const nextStep = await ApiService.getNextStoryStep(next_step_id);
            runInAction(() => {
                if (this.currentStory && this.currentStory.current_step) {
                    this.currentStory.current_step.selected_option = option;
                }
                if (this.currentStory) {
                    this.currentStory.current_step = nextStep;
                    this.steps.push(nextStep);
                }
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = 'Failed to select option';
                this.loading = false;
            });
        }
    }

    
}

export const storyPageStore = new StoryPageStore();
