// App.tsx

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { StoryList } from './components/StoryList';
import { StoryPage } from './components/StoryPage';
import './App.css';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import CookieConsent from './components/CookieConsent';
import { getConsentStatus } from './utils/cookieConsent';

const TRACKING_ID = 'G-BYTTVLDK81';

const AppContainer = styled.div`
    min-height: 100vh;
    background: linear-gradient(
        135deg, 
        #0F0F1B 0%, 
        #1A1A2E 100%
    );
    color: #E1E1E6;
`;

const App: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        // Only initialize Google Analytics if the user consented
        if (getConsentStatus('analytics') && !ReactGA.isInitialized) {
        //if(true){
            ReactGA.initialize(TRACKING_ID, { gaOptions: { anonymizeIp: true } });
        }
    }, []);

    useEffect(() => {
        // Track page views on route change only if consent is given and GA is initialized
        if (getConsentStatus('analytics') && ReactGA.isInitialized) {
        //if(true){
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
        }
    }, [location]);

    return (
        <AppContainer>
            <CookieConsent />
            <Routes>
                <Route path="/" element={<StoryList />} />
                <Route path="/story/:storyId" element={<StoryPage />} />
            </Routes>
        </AppContainer>
    );
};

export default App;
