import styled from 'styled-components';

export const StoryListContainer = styled.div`
    padding: 16px;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        padding: 8px;
    }
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.loading {
        opacity: 0;
    }
`;

export const StoryThumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.loading {
        opacity: 0;
    }

`;
export const StoryCard = styled.div`
    background: ${({ theme }) => theme.colors.card.background};
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.text.primary};
    box-shadow: ${({ theme }) => theme.shadows.card};
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
`;

export const ThumbnailContainer = styled.div`
    height: 200px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    transition: height 0.3s ease-in-out;

    // Mobile styles
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        height: auto;
        & > img {
            height: 10vh;
            transition: height 0.3s ease-in-out;
        }
    }

    // Mobile top thumbnail
    .viewport-top & > img {
        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
            height: 40vh;
            transition: height 0.3s ease-in-out;
        }
    }

    // Desktop hover effect
    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        ${StoryCard}:hover & {
            height: 600px;  // Adjust this value based on your needs
        }
    }
`;

export const StoryTitle = styled.h2`
    margin: 0 0 10px 0;
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.primary};
`;

export const StoryPlot = styled.p`
    margin: 0 0 15px 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.text.secondary};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 1.2rem;
    font-weight: 600;
`;

export const GenreBadge = styled.span`
    background: rgba(0, 255, 157, 0.2);
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.primary};
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 0.9em;
`;

export const LoadingSpinner = styled.div`
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.error};
    text-align: center;
    padding: 20px;
`;
