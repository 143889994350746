import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { storyStore } from '../stores/StoryStore';
import {
    StoryListContainer,
    StoryCard,
    StoryTitle,
    StoryPlot,
    GenreBadge,
    LoadingSpinner,
    ErrorMessage,
    StoryThumbnail,
    ThumbnailContainer
} from '../styles/StoryList.styles';
import { useNavigate } from 'react-router-dom';
import './StoryList.css';  // You'll need to create this

export const StoryList = observer(() => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [topStoryId, setTopStoryId] = useState<string | null | undefined>(null);
    const [bottomStoryId, setBottomStoryId] = useState<string | null | undefined>(null);

    useEffect(() => {
        storyStore.fetchStories();
    }, []);

    useEffect(() => {
        if (!storyStore.isLoading) {
            // Small delay to ensure smooth transition
            setTimeout(() => setIsVisible(true), 100);
        } else {
            setIsVisible(false);
        }
    }, [storyStore.isLoading]);

    useEffect(() => {
        // Only set up the observer on mobile devices
        if (window.innerWidth > 768) return;

        const observer = new IntersectionObserver((entries) => {
            // Get all thumbnail containers that are currently visible in the viewport
            const visibleThumbnails = Array.from(document.querySelectorAll('[data-thumbnail-container]')).filter(element => {
                const rect = element.getBoundingClientRect();
                return rect.top < window.innerHeight && rect.bottom > 0;
            });

            // Sort by their distance from the top of viewport
            const sortedThumbnails = visibleThumbnails.sort((a, b) => {
                const rectA = a.getBoundingClientRect();
                const rectB = b.getBoundingClientRect();
                return rectA.top - rectB.top;
            });

            let topThumbnail = sortedThumbnails[0];

            // Check if we're near the bottom of the page
            const scrollPosition = window.scrollY + window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const isNearBottom = documentHeight - scrollPosition < 100; // 100px threshold

            // If we're near the bottom, use the last visible thumbnail as the top one
            if (isNearBottom && sortedThumbnails.length > 0) {
                topThumbnail = sortedThumbnails[sortedThumbnails.length - 1];
            }

            const topStoryId = topThumbnail?.closest('[data-story-id]')?.getAttribute('data-story-id');
            setTopStoryId(topStoryId);
        }, {
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            rootMargin: '0px'
        });

        // Observe all thumbnail containers
        document.querySelectorAll('[data-thumbnail-container]').forEach(thumbnail => {
            observer.observe(thumbnail);
        });

        return () => observer.disconnect();
    }, [storyStore.stories]);

    const handleStoryClick = (storyId: string) => {
        navigate(`/story/${storyId}`);
    };

    if (storyStore.isLoading) {
        return (
            <StoryListContainer style={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '50vh',
                gap: '1rem'
            }}>
                <LoadingSpinner />
                <p>Hikayeler yükleniyor...</p>
            </StoryListContainer>
        );
    }

    if (storyStore.hasError) {
        return (
            <StoryListContainer style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh'
            }}>
                <ErrorMessage>{storyStore.error}</ErrorMessage>
            </StoryListContainer>
        );
    }

    if (storyStore.isEmpty) {
        return <p>No stories found.</p>;
    }

    return (
        <StoryListContainer className={!isVisible ? 'loading' : ''}>
            {storyStore.stories.map((story) => (
                <StoryCard 
                    key={story.story_id}
                    data-story-id={story.story_id}
                    className={story.story_id === topStoryId ? 'viewport-top' : ''}
                    onClick={() => handleStoryClick(story.story_id)}
                >
                    <StoryTitle>{story.story_title}</StoryTitle>
                    <ThumbnailContainer data-thumbnail-container>
                        <StoryThumbnail 
                            loading="lazy"
                            src={story.image_url}
                            alt={`${story.story_title} thumbnail`}
                            onLoad={(e) => e.currentTarget.classList.remove('loading')}
                            className="loading"
                        />
                    </ThumbnailContainer>
                    <StoryPlot>{story.story_plot}</StoryPlot>
                    <div>
                        {story.story_genres.split(',').map((genre) => (
                            <GenreBadge key={genre.trim()}>
                                {genre.trim()}
                            </GenreBadge>
                        ))}
                    </div>
                </StoryCard>
            ))}
        </StoryListContainer>
    );
});
