import { makeAutoObservable, runInAction } from 'mobx';
import { StoryBrief } from './interfaces';
import { ApiService } from '../services/ApiService';

class StoryStore {
    stories: StoryBrief[] = [];
    loading: boolean = false;
    error: string | null = null;
    isInitialized: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    
    async fetchStories() {
        if (this.isInitialized || this.loading) {
            return;
        }

        this.loading = true;
        try {
            const stories = await ApiService.getStories();
            runInAction(() => {
                this.stories = stories;
                this.loading = false;
                this.isInitialized = true;
            });
        } catch (error) {
            runInAction(() => {
                this.error = 'Failed to fetch stories';
                this.loading = false;
            });
        }
    }

    get isLoading() {
        return this.loading;
    }

    get hasError() {
        return !!this.error;
    }

    get isEmpty() {
        return this.stories.length === 0;
    }
}

export const storyStore = new StoryStore();
