export const theme = {
    colors: {
        primary: '#7b84db',
        secondary: '#ffff9d',
        background: {
            start: '#1a1a2e',
            end: '#16213e'
        },
        text: {
            primary: '#ffffff',
            secondary: '#e0e0e0'
        },
        card: {
            background: 'rgba(0, 0, 0, 0.8)'
        },
        error: '#ff4444'
    },
    fonts: {
        primary: "'Orbitron', sans-serif",
        secondary: "'Cormorant Garamond', 'Cambria', serif"
    },
    breakpoints: {
        mobile: '600px'
    },
    shadows: {
        card: '0 4px 6px rgba(0, 0, 0, 0.3)'
    },
    transitions: {
        default: '0.2s'
    }
};

export type Theme = typeof theme;
