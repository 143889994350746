import styled, { keyframes } from 'styled-components';
import { theme } from '../styles/theme'; // Adjust this import path as needed


const glowAnimation = keyframes`
  0% { text-shadow: 0 0 5px rgba(123, 132, 219, 0.3), 0 0 10px rgba(123, 132, 219, 0.2); }
  50% { text-shadow: 0 0 10px rgba(123, 132, 219, 0.9), 0 0 20px rgba(123, 132, 219, 0.9); }
  100% { text-shadow: 0 0 5px rgba(123, 132, 219, 0.3), 0 0 10px rgba(123, 132, 219, 0.2); }
`;



export const StoryContainer = styled.div`
    max-width: 90%;
    margin: 0 auto;
    padding: 60px 2px 2px; // Add top padding to make room for the BackButton
    color: #E1E1E6;
    position: relative;
    width: 95%;

    @media (max-width: 768px) {
        width: 95%;
        padding: 50px 0 0; // Adjust top padding for mobile
    }
`;

export const StoryImageWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StoryImageContainer = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  perspective: 1000px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StoryImage = styled.img<{ skewX: number; skewY: number }>`
  width: 90%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s ease;
  transform: ${({ skewX, skewY }) => `rotateY(${skewX}deg) rotateX(${-skewY}deg)`};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StoryStep = styled.div`
    margin-bottom: 30px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.08); // Slightly increased opacity for better contrast
    border-radius: 8px;
    border: 1px solid rgba(123, 132, 219, 0.3); // Increased border opacity
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // Increased shadow for depth

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 20px;
    }
`;

export const StoryLine = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    color: #F0F0F5; // Slightly lighter color for better contrast
    margin-bottom: 15px;
    text-align: justify; // Justify the text
    hyphens: none; // Enable hyphenation for better text fitting
    word-break: break-word; // Allow long words to break
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Subtle text shadow for readability

    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5;
    }
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px auto;
    width: 100%; // Changed from 80% to 100%
    max-width: 500px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 10px;
    }
`;

export const OptionButton = styled.button`
    padding: 12px 20px;
    background-color: rgba(123, 132, 219, 0.2);
    color: #E1E1E6;
    border: 1px solid rgba(123, 132, 219, 0.4); // Added border for definition
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
    text-align: left; // Align text to the left
    word-break: break-word; // Allow long words to break

    &:hover {
        background-color: rgba(123, 132, 219, 0.4);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        font-size: 0.95rem;
        padding: 10px 15px;
    }
`;

export const SelectedOption = styled.div`
    margin-top: 15px;
    padding: 10px 15px;
    background: rgba(123, 132, 219, 0.15);
    border-left: 3px solid #7B84DB;
    color: #E1E1E6;
    font-style: italic;
    text-align: center; // Center the text

    @media (max-width: 768px) {
        animation: selectionAnimation 1.5s ease-in-out; // Add animation for mobile
    }
`;

// Add keyframes for the selection animation
const selectionAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;

export const LoadingOverlay = styled.div<{ $initial?: boolean }>`
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 25px;
    background: rgba(15, 15, 27, 0.9);
    border-radius: 8px;
    border: 1px solid rgba(123, 132, 219, 0.3);
    color: #E1E1E6;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;


export const BackButton = styled.button`
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px 15px;
    background-color: rgba(123, 132, 219, 0.2);
    color: #E1E1E6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(123, 132, 219, 0.4);
    }

    @media (max-width: 768px) {
        top: 10px;
        left: 10px;
        padding: 8px 12px;
        font-size: 0.9rem;
    }
`;

export const StoryHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 30px;
    padding: 20px;
    background-color: rgba(74, 74, 90, 0.2);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 15px 10px;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 0;
    }
`;

export const StoryTitle = styled.h1`
    font-size: 2.5rem;
    color: #E1E1E6;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    animation: ${glowAnimation} 3s ease-in-out infinite;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-top: 10px;
    }
`;

export const StoryPlot = styled.p`
  font-size: 1.1rem;
  color: #D1D1D6;
  text-align: center;
  width: 100%;
  margin: 0 0 20px;
  line-height: 1.6;
  font-family: 'Playfair Display', 'Cambria', serif;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
