import React, { useState, useEffect } from 'react';
import { StoryImage } from '../styles/StoryPage.styles';

interface RetryImageProps {
    src: string;
    alt: string;
    style?: any;
    maxRetries?: number;
    retryInterval?: number;
}

export const RetryImage: React.FC<RetryImageProps> = ({
    src,
    alt,
    style,
    maxRetries = 120, 
    retryInterval = 1000,
}) => {
    const [imgSrc, setImgSrc] = useState<string>(src);
    const [retryCount, setRetryCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        const img = new Image();

        const loadImage = () => {
            img.onload = () => {
                setLoaded(true);
                setImgSrc(src);
            };

            img.onerror = () => {
                if (retryCount >= maxRetries) {
                    setFailed(true);
                    return;
                }
                timeoutId = setTimeout(() => {
                    setRetryCount(prev => prev + 1);
                }, retryInterval);
            };

            img.src = src;
        };

        loadImage();

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            img.onload = null;
            img.onerror = null;
        };
    }, [src, retryCount, maxRetries, retryInterval]);

    useEffect(() => {
        // Reset retry count and states when src changes
        setRetryCount(0);
        setLoaded(false);
        setFailed(false);
    }, [src]);

    if (failed) {
        return <div></div>;
    }

    return (
        <div style={{ position: 'relative' }}>
            <StoryImage
                src={loaded ? imgSrc : ''}
                alt={alt}
                skewX={0}
                skewY={0}
                style={{
                    ...style,
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                }}
            />
            {!loaded && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: '#666',
                }}>
                    Resim yükleniyor, bu biraz zaman alabilir...
                </div>
            )}
        </div>
    );
}; 