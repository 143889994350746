import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { storyPageStore } from '../stores/StoryPageStore';
import { LoadingSpinner } from '../components/LoadingSpinner';
import {
    StoryContainer,
    StoryImageContainer,
    StoryStep as StoryStepComponent,
    StoryLine,
    OptionsContainer,
    OptionButton,
    SelectedOption,
    LoadingOverlay,
    StoryTitle,
    StoryPlot,
    StoryHeader,
    BackButton,
} from '../styles/StoryPage.styles';
import { useSpring, animated, to } from 'react-spring';
import { StoryStep, Option } from '../stores/interfaces';
import { RetryImage } from './RetryImage';

export const StoryPage = observer(() => {
    const { storyId } = useParams();
    const navigate = useNavigate();
    const latestStepRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const [optionsDisabled, setOptionsDisabled] = useState<boolean>(false);
    const titleRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (storyId && (!storyPageStore.currentStory?.story_brief.story_id || 
            storyPageStore.currentStory.story_brief.story_id !== storyId)) {
            storyPageStore.loadStory(storyId);
        }
    }, [storyId]);

    useEffect(() => {
        if (storyPageStore.steps.length === 1 && titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (latestStepRef.current && storyPageStore.steps.length > 1) {
            latestStepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [storyPageStore.steps.length]);

    const getSelectedOptionText = (step: StoryStep) => {
        return step.selected_option?.option_text || 'No option selected';
    };

    function handleOptionSelect(option: Option) {
        if (optionsDisabled) {
            return;
        }
        // Disable option buttons
        setOptionsDisabled(true);
    
        // Send the request here
        storyPageStore.selectOption(option);

        // Re-enable option buttons after the request
        setOptionsDisabled(false);
    }

    const handleBackClick = () => {
        navigate('/'); // Adjust this path as needed
    };

    const [{ skewX, skewY }, api] = useSpring(() => ({ skewX: 0, skewY: 0, config: { mass: 1, tension: 170, friction: 26 } }));

    const handleInteraction = (clientX: number, clientY: number) => {
        if (imageRef.current) {
            const { left, top, width, height } = imageRef.current.getBoundingClientRect();
            const centerX = left + width / 2;
            const centerY = top + height / 2;
            const deltaX = clientX - centerX;
            const deltaY = clientY - centerY;
            api.start({ skewX: -(deltaX / width) * 20, skewY: (deltaY / height) * 20 });
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        handleInteraction(e.clientX, e.clientY);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        const touch = e.touches[0];
        handleInteraction(touch.clientX, touch.clientY);
    };

    const handleInteractionEnd = () => {
        api.start({ skewX: 0, skewY: 0 });
    };

    return (
        <StoryContainer style={{ opacity: storyPageStore.loading ? 0.5 : 1 }}>
            {storyPageStore.loading && (
                <LoadingOverlay $initial={true}>
                    <LoadingSpinner size="small" />
                    <span>Hiç girilmemiş bir patikaya giriyorsun...</span>
                </LoadingOverlay>
            )}

            <BackButton onClick={handleBackClick}>← Hikayeler</BackButton>

            <StoryHeader>
                <StoryTitle ref={titleRef}>{storyPageStore.currentStory?.story_brief.story_title || ""}</StoryTitle>
                <StoryPlot>{storyPageStore.currentStory?.story_brief.story_plot || ""}</StoryPlot>
                {storyPageStore.currentStory && (
                    <StoryImageContainer
                        ref={imageRef}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleInteractionEnd}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleInteractionEnd}
                    >
                        <animated.div
                            style={{
                                transform: to(
                                    [skewX, skewY],
                                    (x, y) => `perspective(600px) rotateX(${y}deg) rotateY(${x}deg)`
                                )
                            }}
                        >
                            <RetryImage 
                                src={storyPageStore.currentStory?.story_brief.image_url || ''}
                                alt="Story thumbnail"
                            />
                        </animated.div>
                    </StoryImageContainer>
                )}

                {storyPageStore.steps.map((step, index) => (
                    <StoryStepComponent 
                        key={step.story_step_id}
                        ref={index === storyPageStore.steps.length - 1 ? latestStepRef : null}
                    >
                        {step.image_url && (
                            <StoryImageContainer>
                                <RetryImage 
                                    src={step.image_url}
                                    alt={`Story step ${index + 1}`}
                                    style={{
                                        transform: 'none'  // or whatever transform you want
                                    }}
                                />
                            </StoryImageContainer>
                        )}
                        <StoryLine>{step.story_line}</StoryLine>
                        {step.selected_option ? (
                            <SelectedOption>
                                {getSelectedOptionText(step)}
                            </SelectedOption>
                        ) : (
                            index === storyPageStore.steps.length - 1 && (
                                <OptionsContainer>
                                    {step.options?.map(option => (
                                        <OptionButton 
                                            key={option.option_id}
                                            onClick={() => handleOptionSelect(option)}
                                            disabled={optionsDisabled || storyPageStore.loading}
                                        >
                                            {option.option_text}
                                        </OptionButton>
                                    ))}
                                </OptionsContainer>
                            )
                        )}
                    </StoryStepComponent>
                ))}
            </StoryHeader>
        </StoryContainer>
    );
});
